import {
  Comment01Icon,
  Contact01Icon,
  Ticket02Icon,
  Home01Icon,
  UserMultiple02Icon,
  Settings01Icon,
  GlobeIcon,
  MessengerIcon,
  Tag01Icon,
  Logout02Icon,
  Link02Icon,
} from "hugeicons-react";

import { IoLogoFacebook, IoLogoWhatsapp } from "react-icons/io";
import { IoCall, IoMail } from "react-icons/io5";

const mainMenuIconSize = 20;

const menuOptions = [
  {
    section: "Support",
    options: [
      {
        id: "/",
        title: "Dashboard",
        Icon: (props) => (
          <Home01Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
      {
        id: "chats",
        title: "Chats",
        Icon: (props) => (
          <Comment01Icon color={props.color} size={mainMenuIconSize} />
        ),
      },

      {
        id: "contacts",
        title: "Contacts",
        Icon: (props) => (
          <Contact01Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
      {
        id: "tags",
        title: "Tags",
        Icon: (props) => (
          <Tag01Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
    ],
  },
  {
    section: "Manage",
    options: [
      {
        id: "users",
        title: "Users",
        Icon: (props) => (
          <UserMultiple02Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
      {
        id: "channels",
        title: "Channels",
        Icon: (props) => (
          <Ticket02Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
    ],
  },
  {
    section: "Account",
    options: [
      {
        id: "integrations",
        title: "Integrations",
        Icon: (props) => (
          <GlobeIcon color={props.color} size={mainMenuIconSize} />
        ),
      },

      {
        id: "settings",
        title: "Settings",
        Icon: (props) => (
          <Settings01Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
    ],
  },
  {
    section: "",
    options: [
      {
        id: "website",
        title: "Website",
        url: "https://qqura.com",
        Icon: (props) => (
          <Link02Icon color={props.color} size={mainMenuIconSize} />
        ),
      },

      {
        id: "logout",
        title: "Logout",

        Icon: (props) => (
          <Logout02Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
    ],
  },
];

/**
 *    {
        id: "routing",
        title: "Routing",
        Icon: (props) => (
          <Route01Icon color={props.color} size={mainMenuIconSize} />
        ),
      },

         {
        id: "reporting",
        title: "Reporting",
        Icon: (props) => (
          <Analytics02Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
 */

let iconSize = 15;

export const contactIcons = {
  phone: <IoCall size={iconSize} />, // Replace with actual icon component or HTML for phone
  email: <IoMail size={iconSize} />, // Replace with actual icon component or HTML for email
  facebook: <IoLogoFacebook size={iconSize} color="#0042FF" />, // Replace with actual icon component or HTML for Facebook
  whatsapp: <IoLogoWhatsapp size={iconSize} color="#007F0F" />, // Replace with actual icon component or HTML for WhatsApp
  messenger: <MessengerIcon color="#4B5AFF" size={iconSize} />, // Replace with actual icon component or HTML for Messenger
};

const constants = {
  //apiUrl: "http://localhost:4000",
  adminPhones: ["15550282933", "27658932664", "27784630811", "16502509316"],
  adminEmails: [
    "admin@drivetoown.co.za",
    "mailing@drivetoown.co.za",
    "sales@drivetoown.co.za",
    "hello@qqura.com",
    "hello@qflit.com",
    "support@softwareservices.com",
  ],
  adminPageIDs: ["123456789"],
  //notifier: "ws://127.0.0.1:4000",
  apiUrl: "https://api.qqura.com",
  notifier: "wss://api.qqura.com",
};
//,
export { constants, menuOptions };
