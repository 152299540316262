import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    _id: null,
    firstName: null,
    isNext: false,
    lastName: null,
    role: null,
    email: null,
  },
  reducers: {
    updateAuth(state, action) {
      return { ...action.payload };
    },
  },
});

export const { updateAuth } = authSlice.actions;
export default authSlice.reducer;
