import { createSlice } from "@reduxjs/toolkit";
import { constants } from "../../config/constants";

const commentsSlice = createSlice({
  name: "comments",
  initialState: [],
  reducers: {
    bulkAddChats(state, action) {
      return [...action.payload];
    },
    addChat(state, action) {
      return [action.payload, ...state];
    },

    updateChat(state, action) {
      let index = state.findIndex(
        (msg) => msg._id === action.payload.message._id
      );
      if (index === -1) {
        return [
          {
            ...action.payload.message,
            unread: 1,
            date: action.payload.message.date,
          },
          ...state,
        ];
      }
    },
  },
});

export const { bulkAddChats, addChat, updateChat } = commentsSlice.actions;
export default commentsSlice.reducer;
