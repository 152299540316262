import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { initializeApp } from "firebase/app";
const config = {
  databaseURL: "https://your-database-name.firebaseio.com",
  apiKey: "AIzaSyAt7mZxw9bA5xcwsr6MJUtDUJD0hVI9XoA",
  authDomain: "spring-ship-233716.firebaseapp.com",
  projectId: "spring-ship-233716",
  storageBucket: "spring-ship-233716.appspot.com",
  messagingSenderId: "614671434091",
  appId: "1:614671434091:web:6f83ceb4b7ab47829f8c2e",
  measurementId: "G-20RRH8ZLZN",
};

initializeApp(config);

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
