import React, { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LinearProgress, Stack } from "@mui/material";
import { store, persistor } from "./state/store/store";
import "./index.css";

// Lazy-loaded components
const ErrorPage = lazy(() => import("./routes/error-page"));
const Root = lazy(() => import("./layouts/root/view"));
const Chats = lazy(() => import("./pages/chats/view"));
const Channels = lazy(() => import("./pages/channels/index"));
const Settings = lazy(() => import("./pages/settings/index"));
const Integrations = lazy(() => import("./pages/integrations/index"));
const Reporting = lazy(() => import("./pages/reporting/index"));
const Routing = lazy(() => import("./pages/routing/index"));
const Dashboard = lazy(() => import("./pages/home/index"));
const Contacts = lazy(() => import("./pages/contacts/list/view"));
const Tags = lazy(() => import("./pages/tags/list/view"));
const ViewContact = lazy(() => import("./pages/contacts/view-contact/view"));
const ResetPassword = lazy(() => import("./pages/authentication/reset"));
const ForgotPassword = lazy(() => import("./pages/authentication/forgot"));
const Login = lazy(() => import("./pages/authentication/login"));
const Users = lazy(() => import("./pages/users/list/view"));
const ViewUser = lazy(() => import("./pages/users/view-user/view"));
const JoinOrganisation = lazy(() => import("./pages/authentication/join"));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xsm: 480,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#007BFF",
      dark: "#0056B3",
    },
    secondary: {
      main: "#FFC107",
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#212529",
      secondary: "#6C757D",
    },
    error: {
      main: "#DC3545",
    },
    success: {
      main: "#28A745",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const LoaderComponent = () => (
  <Stack
    sx={{
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <LinearProgress color="inherit" sx={{ width: "120px", borderRadius: 32 }} />
  </Stack>
);

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        errorElement={
          <Suspense fallback={<LoaderComponent isRoot={true} />}>
            <ErrorPage />
          </Suspense>
        }
      >
        <Route
          path="/"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <Root />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="chats"
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Chats />
              </Suspense>
            }
          />
          <Route
            path="settings"
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Settings />
              </Suspense>
            }
          />
          <Route
            path="channels"
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Channels />
              </Suspense>
            }
          />
          <Route
            path="routing"
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Routing />
              </Suspense>
            }
          />
          <Route
            path="integrations"
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Integrations />
              </Suspense>
            }
          />
          <Route
            path="reporting"
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Reporting />
              </Suspense>
            }
          />
          <Route path="users">
            <Route
              index
              element={
                <Suspense fallback={<LoaderComponent />}>
                  <Users />
                </Suspense>
              }
            />
            <Route
              path=":userID"
              element={
                <Suspense fallback={<LoaderComponent />}>
                  <ViewUser />
                </Suspense>
              }
            />
          </Route>
          <Route path="contacts">
            <Route
              index
              element={
                <Suspense fallback={<LoaderComponent />}>
                  <Contacts />
                </Suspense>
              }
            />
            <Route
              path=":contactID"
              element={
                <Suspense fallback={<LoaderComponent />}>
                  <ViewContact />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="tickets"
            element={<Stack sx={{ p: 4 }}>Tickets</Stack>}
          />
          <Route
            path="contacts"
            element={<Stack sx={{ p: 4 }}>Contacts</Stack>}
          />
          <Route
            path="tags"
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Tags />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="login"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="join"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <JoinOrganisation />
            </Suspense>
          }
        />
        <Route
          path="forgot"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="reset"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <ResetPassword />
            </Suspense>
          }
        />
      </Route>
    )
  );

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate
          loading={
            <div>
              <p>Loading...</p>
            </div>
          }
          persistor={persistor}
        >
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
