import { configureStore } from "@reduxjs/toolkit";

import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "../../api/api.service";
import userReducer from "../slices/user";
import conversationReducer from "../slices/conversation";
import chatsReducer from "../slices/chats";
import composeReducer from "../slices/compose";
import menuReducer from "../slices/menu";
import dialogReducer from "../slices/dialog";
import paginationReducer from "../slices/pagination";
import snackReducer from "../slices/snack";
import commentsReducer from "../slices/comments";
import templatesReducer from "../slices/templates";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import contactsReducer from "../slices/contacts";
import authReducer from "../slices/auth";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["conversation", "chats", "compose", "pagination", "menu"],
};

const rootReducer = combineReducers({
  user: userReducer,
  snack: snackReducer,
  chats: chatsReducer,
  templates: templatesReducer,
  pagination: paginationReducer,
  conversation: conversationReducer,
  compose: composeReducer,
  contacts: contactsReducer,
  menu: menuReducer,
  dialog: dialogReducer,
  comments: commentsReducer,
  auth: authReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
