import { createSlice } from "@reduxjs/toolkit";

const templateSlice = createSlice({
  name: "templates",
  initialState: [],
  reducers: {
    updateTemplates(state, action) {
      return [...action.payload];
    },
  },
});

export const { updateTemplates } = templateSlice.actions;
export default templateSlice.reducer;
