import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
  name: "menu",
  initialState: false,
  reducers: {
    updateMenu(state, action) {
      return action.payload;
    },
  },
});

export const { updateMenu } = menuSlice.actions;
export default menuSlice.reducer;
