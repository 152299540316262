// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./auth-header";
import { setUser } from "../state/slices/user";
import { constants } from "../config/constants";
import { updateDialog } from "../state/slices/dialog";

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // const user = api.getState().user;
  let authorization = authHeader();
  const baseQuery = fetchBaseQuery({
    baseUrl: constants.apiUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("authorization", authorization);
      //  user.workspace && headers.set("workspace", user.workspace._id);
      return headers;
    },
  });
  let result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 401) {
      api.dispatch(setUser({ user: null }));
    } else {
      let msg = "";
      if (result.error.data) {
        msg = result.error.data.error;
      } else {
        msg =
          "Request could not be fulfilled. Please check your internet connection.";
      }
      api.dispatch(updateDialog({ open: true, msg }));
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ["User", "Contact", "Note"],
  endpoints: () => ({}),
});
