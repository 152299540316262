import { createSlice } from "@reduxjs/toolkit";

const contactsSlice = createSlice({
  name: "contacts",
  initialState: [],
  reducers: {
    bulkAddContacts(state, action) {
      return [...action.payload];
    },
  },
});

export const { bulkAddContacts } = contactsSlice.actions;
export default contactsSlice.reducer;
