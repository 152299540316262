import { createSlice } from "@reduxjs/toolkit";

const composeSlice = createSlice({
  name: "compose",
  initialState: {
    to: [],
    inReplyTo: "",
    cc: [],
    subject: null,
  },
  reducers: {
    updateCompose(state, action) {
      return { ...state, ...action.payload };
    },
    resetCompose(state, action) {
      return { to: [], inReplyTo: "", cc: [], subject: null };
    },
  },
});

export const { updateCompose, resetCompose } = composeSlice.actions;
export default composeSlice.reducer;
